import React, { Fragment } from 'react';
import { useAppSelector } from '../hooks/hooks';
import { useGetDirectoryQuery } from '../features/api/api';
import { Result } from './result';
import { Pagination } from './pagination';
import { useAppDispatch } from '../app/store';
import { updateSkip } from '../features/directory/directory-slice';
import MediaQuery from 'react-responsive';
import { DefineAdSlot } from '../../reactComponents/gptAds';
import { LoadingSpinner } from '../../reactComponents/loader';

export interface ResultsProps {
    hideResults: boolean;
    removeAdSlot?: (slotId: string) => void;
    addAdSlot?: (slotId: string, slot: any) => void;
    adSlots?: any;
    adSlotCount?: number;
    setAdSlotCount?: (number, any) => void;
}
const resultsBeforeAd = 2;
export const Results = ({ hideResults, removeAdSlot = null, addAdSlot = null, adSlots = null, adSlotCount = null, setAdSlotCount = null }: ResultsProps) => {
    const { apiParams } = useAppSelector(state => state.directory);
    const dispatch = useAppDispatch();
    const { collapseButtonText, expandButtonText, useModal, hiddenResultsMessage, noResultsMessage, sidebarAds } = useAppSelector(state => state.settings);
    const { data, isLoading, isFetching } = useGetDirectoryQuery(apiParams);

    const updateSkipValue = (newSkip: number) => {
        dispatch(updateSkip({ newSkip: newSkip }))
    }

    const minResultIndex = apiParams.skip + 1;
    const maxResultIndex = data && ((apiParams.skip + apiParams.limit) <= data.total ? (apiParams.skip + apiParams.limit) : data.total) || 0;

    const renderNoResults = () => {
        return <div className="directory-results-message"><i className="ph ph-info"></i><div dangerouslySetInnerHTML={{ __html: noResultsMessage }}></div></div>
    }

    const renderHiddenResultsMessage = () => {
        return <div className="directory-results-message"><div dangerouslySetInnerHTML={{ __html: hiddenResultsMessage }}></div></div>
    }

    const renderResults = () => {
        return (
            <>
                {hideResults ? renderHiddenResultsMessage() :
                    <>
                        <p className="result-count bold">Showing {minResultIndex}-{maxResultIndex} of {data.total} results</p>
                        <div className="directory-listing-cards" key="results-container">{data.results.map((result, index) => 
                            <Fragment key={"directory-fragment-"+ result.id }>
                                <Result
                                    result={result}
                                    expandButtonText={expandButtonText}
                                    collapseButtonText={collapseButtonText}
                                    showToggle={true}
                                    useModal={useModal}
                                    key={"directory-card-" + result.id}
                                    id={result.id}
                                />
                                <MediaQuery maxWidth={1024}>
                                    {((resultsBeforeAd == index + 1 || data.results.length == 1) && sidebarAds && sidebarAds.length > 0) &&
                                        <>

                                            {sidebarAds && sidebarAds.length > 0 && sidebarAds.map((slot, i) => (
                                                <DefineAdSlot setAdSlotCount={setAdSlotCount} adSlots={adSlots} adSlotCount={adSlotCount} updateAdSlots={addAdSlot} removeSlotId={removeAdSlot} adUnit={slot.id} size={JSON.parse("[" + slot.size + "]")} key={"directory-ad-" + i} />
                                            ))}
                                        </>}
                                </MediaQuery >
                            </Fragment>
                        
                        )}
                        </div>
                        {data.total > apiParams.limit &&
                            <Pagination
                                currentPage={apiParams.skip / apiParams.limit + 1}
                                pageSize={apiParams.limit}
                                totalItems={data.total}
                                updateSkip={updateSkipValue}
                                numberOfPageButtons={7}
                            />
                        }
                    </>
                }
            </>
            
            )
    }


    const renderData = () => {
        return (
            <>
                {!data || !data.results || data.results.length === 0 ? renderNoResults() : renderResults()}
            </>
        )
    }

    return (
        <div className="directory-results">
            {isLoading || isFetching ? <LoadingSpinner loading={false} /> : renderData() }
        </div>
    )
}