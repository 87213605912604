import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import { useAppSelector } from './hooks/hooks';
import { useAppDispatch } from './app/store';
import { matchStateToQueryString, updateSort } from './features/directory/directory-slice';
import { SelectOption } from './helpers/common-types';
import { SortSelector } from './components/sort-selector';
import { useGetDirectoryQuery } from './features/api/api';
import { scrollToTarget } from '../global/helpers/scrollHelper';
import { isNullOrEmpty } from '../global/helpers/data-helpers';
import { LoadingSpinner } from '../reactComponents/loader';
import { Alert } from '../reactComponents/error';
import { addAdSlot, incrementAdSlots, removeAdSlots, requestGoogleAds } from '../reactComponents/gptAds';

const Results = lazy(() => import('./components/results'));
const DirectorySidebar = lazy(() => import('./components/directory-sidebar'));
const DirectorySearch = lazy(() => import('./components/directory-search'));
function App() {
    const { apiParams } = useAppSelector(state => state.directory);
    const { showSearch, hideResultsUntilSearch, errorMessage, hideSort } = useAppSelector(state => state.settings);
    const { data, isSuccess, isLoading, isFetching, isError } = useGetDirectoryQuery(apiParams);
    const dispatch = useAppDispatch();
    const [adSlots, setAdSlots] = useState([]);
    const [adSlotCount, setAdSlotCount] = useState(0);
    const hasQueryParams = () => {
        if (!isNullOrEmpty(window.location.search)) {
            var params = new URLSearchParams(window.location.search);
            // we only need to check for the page param, as it automatically is added if the user interacts with filters, sorts, etc.
            return params.has("page");
        }
        return false;
    }

    // hide results if option is enabled and there are no query params
    const [hideResults, setHideResults] = useState(hideResultsUntilSearch && !hasQueryParams());

    

    const updateHiddenState = (value: boolean) => {
        setHideResults(value);
    }

    useEffect(() => {
        window.onpopstate = e => {
            dispatch(matchStateToQueryString());
        }
    })

    const appRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        scrollToTarget(appRef.current)
    },
        [apiParams.skip]);

    useEffect(() => {
        requestGoogleAds(adSlots, adSlotCount);
    }, [adSlotCount]);

    const getSortValues = () => {
        if (!data || !data.sortOptions) {
            return [];
        }
        let sortValues = data.sortOptions.map((sortOption) => new SelectOption(`${sortOption.column}:${sortOption.direction}`, sortOption.displayName));
        return sortValues;
    }

    const getSelectedSortValue = () => {
        if (!data || !data.sortOptions) {
            return null;
        }
        var selected = data.sortOptions.filter((option) => option.isSelected)[0];
        return selected && `${selected.column}:${selected.direction}`;
    }

    const updateSortValue = (value: string) => {
        var column = parseInt(value.split(":")[0]);
        var direction = parseInt(value.split(":")[1]);
        dispatch(updateSort({ newColumn: column, newDirection: direction }));
        updateHiddenState(false);
    }

    const renderErrorMessage = () => {
        return (
            <Alert isError={true} textContent={errorMessage}/>
        )
    }

    const renderContent = () => {
        return (
            <>
                {data &&
                    <>
                    <Suspense fallback={<LoadingSpinner loading={false} />}><DirectorySidebar updateHiddenState={updateHiddenState} setAdSlotCount={(adSlotCount) => { incrementAdSlots(adSlotCount, setAdSlotCount) }} adSlots={adSlots} adSlotCount={adSlotCount} addAdSlot={(slotId, slot) => { addAdSlot(adSlots, slotId, slot, setAdSlots) }} removeAdSlot={(slotId) => removeAdSlots(adSlots, slotId, setAdSlots)} /></Suspense>
                        <div className="directory-listing-content">
                        <div className="directory-listing-context">
                            {showSearch && <Suspense fallback={<LoadingSpinner loading={false} />}><DirectorySearch updateHiddenState={updateHiddenState} /></Suspense>}
                                {!hideSort && <SortSelector
                                    sortLabel="Sort by"
                                    sortOptions={getSortValues()}
                                    currentSort={getSelectedSortValue()}
                                    updateSort={updateSortValue}
                                />
                                }
                            </div>
                        <Suspense fallback={<LoadingSpinner loading={false} />}><Results hideResults={hideResults} setAdSlotCount={(adSlotCount) => { incrementAdSlots(adSlotCount, setAdSlotCount) }} adSlots={adSlots} adSlotCount={adSlotCount} addAdSlot={(slotId, slot) => { addAdSlot(adSlots, slotId, slot, setAdSlots) }} removeAdSlot={(slotId) => removeAdSlots(adSlots, slotId, setAdSlots)} key="directory-results"/></Suspense>
                        </div>
                    </>
                }
            </>
        )
    }

    const renderApp = () => {
        return (
            <>
                {isError ? renderErrorMessage() : renderContent()}
            </>
        )
    }

    return (
        <div className="directory-listing" ref={appRef}>
            <div className="content-container">
                {(isLoading || isFetching) ? <LoadingSpinner loading={false} /> : renderApp()}
            </div>
        </div>
    )
}

export default App;