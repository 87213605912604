import React, { useEffect, useRef } from "react";
import { Result, ResultProps } from "./result";


export const ResultModal = (props: { resultProps: ResultProps, closeCallback: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { result, expandButtonText, collapseButtonText } = props.resultProps;
    const setModalOpen = props.closeCallback;
    const modalRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    },
        []);

    const handleOutsideClick = (e: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            setModalOpen(false);
        }
    }
    return (
        <div className="directory-listing-card-modal" ref={modalRef}>
            <div className="directory-listing-card-modal-content">
                <button className="btn btn-primary" onClick={() => setModalOpen(false)}><i className="ph ph-x"></i></button>
                <Result
                    result={result}
                    expandButtonText={expandButtonText}
                    collapseButtonText={collapseButtonText}
                    showToggle={false}
                    isOpen={true}
                    id={result.id}
                    key={"directory-card-modal-" + result.id}
                    />
            </div>
        </div>
    )
}