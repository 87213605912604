import React, { useState } from 'react';
import { DirectoryPageCard, DirectoryPageCardItem } from '../helpers/common-types';
import { ResultModal } from './result-modal';
import { isNullOrEmpty } from '../../global/helpers/data-helpers';
import MediaQuery from 'react-responsive';
import { convertSymbols } from '../../global/helpers/symbol-helpers';

export interface ResultProps {
    result: DirectoryPageCard;
    expandButtonText: string;
    collapseButtonText: string;
    showToggle: boolean;
    id: number;
    useModal?: boolean;
    isOpen?: boolean;
}

export const Result = (props: ResultProps) => {
    const [isOpened, setIsOpened] = useState(!!props.isOpen);
    const { result, showToggle, useModal, id } = props;
    const renderCell = (item: DirectoryPageCardItem, cellIndex: number) => {
        var cell: JSX.Element | null = null;
        switch (item.dataType) {
            case 'PlainText':
                cell = renderText(item);
                break;
            case 'Image':
                cell = renderImage(item);
                break;
            case 'Email':
                cell = renderEmail(item);
                break;
            case 'URL':
                var newItem = { ...item }
                // check if the link was not a hyperlink in excel, but a text value
                if (isNullOrEmpty(item.fieldValue.cellLinkAddress) && !isNullOrEmpty(item.fieldValue.cellTextValue)) {
                    newItem.fieldValue = { ...item.fieldValue, cellImageAddress: item.fieldValue.cellTextValue };
                }
                cell = renderUrl(newItem);
                break;
            case 'FormattedText':
                cell = renderHtml(item);
                break;
            case 'CommaSeparated':
                cell = renderList(item, id, cellIndex);
                break;
            case 'ImageUrl':
                var newItem = { ...item }
                if (!isNullOrEmpty(item.fieldValue.cellLinkAddress)) {

                    newItem.fieldValue = { ...item.fieldValue, cellImageAddress: item.fieldValue.cellLinkAddress };
                }
                else if (!isNullOrEmpty(item.fieldValue.cellTextValue)) {
                    newItem.fieldValue = { ...item.fieldValue, cellImageAddress: item.fieldValue.cellTextValue };
                }
                cell = renderImage(newItem);
                break;
            case "Date":
                cell = renderText(item);
                break;
            default:
                cell = null;
        }

        return (
            <div className="directory-listing-card-cell" data-field-type={item.dataType} key={`directory-card-${id}-cell-${cellIndex}`}>
                {item.fieldTitle && renderFieldTitle(item)}
                { cell }
            </div>
        )
    }

    const renderFieldTitle = (item: DirectoryPageCardItem) => {
        if (item.fieldTitle) {
            if (item.fieldTitleDisplay == "heading") {
                return <h3 className="field-title-heading h6-style">{convertSymbols(item.fieldTitle)}</h3>
            }
            else {
                return <span className="field-title-label bold large">{convertSymbols(item.fieldTitle)}: </span>
            }
        }
        return null;
    }

    const renderText = (item: DirectoryPageCardItem) => {
        return (
            <span className="large">{convertSymbols(item.fieldValue.cellTextValue)}</span>
        )
    }

    const renderImage = (item: DirectoryPageCardItem) => {
        return (
            <img src={item.fieldValue.cellImageAddress} />
        )
    }

    const renderEmail = (item: DirectoryPageCardItem) => {
        return (
            <a className="large" href={`mailto:${item.fieldValue.cellTextValue}`}>{convertSymbols(item.fieldValue.cellTextValue)}</a>
        )
    }

    const renderUrl = (item: DirectoryPageCardItem) => {
        return (
            <a className="large" href={item.fieldValue.cellLinkAddress}>{convertSymbols(item.fieldValue.cellTextValue)}</a>
        )
    }

    const renderHtml = (item: DirectoryPageCardItem) => {
        return (
            <span dangerouslySetInnerHTML={{ __html: convertSymbols(item.fieldValue.cellTextValue, false, false) }} />
        )
    }

    const renderList = (item: DirectoryPageCardItem, cardIndex: number, cellIndex: number) => {
        return (
            <ul>
                {item.fieldValue.cellTextValue.split(',').map((listItem, index) => <li className="large" key={`card-${cardIndex}-cell-${cellIndex}-item-${index}`}>{convertSymbols(listItem)}</li>)}
            </ul>
        )
    }

    const hasSecondaryData = result.secondaryFullWidth.length > 0 || result.secondaryLeftData.length > 0 || result.secondaryRightData.length > 0;
    

    return (
        <div className={`directory-listing-card-wrap ${isOpened ? 'open' : 'closed'} ${result.isHighlighted ? 'directory-listing-highlight' : ''}`}>
            <div className="directory-listing-card">
                <div className="primary-data">
                    <div className="primary-data-header">{result.title && <h2 className="h4-style primary-data-header-title">{convertSymbols(result.title.fieldValue.cellTextValue)}</h2>}
                        <MediaQuery minWidth={640} key={"toggle-desktop-"+result.id }>
                            {showToggle && hasSecondaryData && <div className="directory-listing-card-toggle"><button className="btn btn-primary" onClick={() => setIsOpened(!isOpened)}>{isOpened ? props.collapseButtonText : props.expandButtonText}</button></div>}
                        </MediaQuery>
                    </div>
                    <div className="primary-data-flex">{result.image && renderCell(result.image, 0)}
                        <div className="primary-left-data">
                            {result.primaryLeftData.map((item: DirectoryPageCardItem, index: number) => { return renderCell(item, index) })}
                        </div>
                    </div>
                    <MediaQuery maxWidth={640} key={"toggle-mobile-" + result.id}>
                        {showToggle && !isOpened && hasSecondaryData && <button className="directory-listing-card-toggle directory-listing-card-toggle-mobile btn btn-primary" onClick={() => setIsOpened(!isOpened)}>{isOpened ? props.collapseButtonText : props.expandButtonText}</button>}
                    </MediaQuery>
                </div>
                {!useModal && isOpened && 
                    <div className={"secondary-data"}>
                        {result.secondaryFullWidth.length > 0 &&
                            <div className="secondary-full-width-data">
                                {result.secondaryFullWidth.map((item: DirectoryPageCardItem, index: number) => { return renderCell(item, index) })}
                            </div>}
                        {result.secondaryLeftData.length > 0 && 
                            <div className="secondary-left-data">
                                {result.secondaryLeftData.map((item: DirectoryPageCardItem, index: number) => { return renderCell(item, index) })}
                            </div>    
                        }
                        {result.secondaryRightData.length > 0 &&
                            <div className="secondary-right-data">
                                {result.secondaryRightData.map((item: DirectoryPageCardItem, index: number) => { return renderCell(item, index) })}
                            </div>}
                        <MediaQuery maxWidth={640} key={"toggle-mobile-modal-" + result.id}>
                            {showToggle && hasSecondaryData && <button className="directory-listing-card-toggle directory-listing-card-toggle-mobile btn btn-primary" onClick={() => setIsOpened(!isOpened)}>{isOpened ? props.collapseButtonText : props.expandButtonText}</button>}
                        </MediaQuery>
                    </div>}
                
            </div>
            {useModal && isOpened && 
                <ResultModal resultProps={props} closeCallback={setIsOpened} />
            }
        </div>
        
    )


}