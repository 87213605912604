import React, { useState } from 'react';
import { useAppSelector } from '../hooks/hooks';
import { useAppDispatch } from '../app/store';
import { resetFilters, updateFilter } from '../features/directory/directory-slice';
import { useGetDirectoryQuery } from '../features/api/api';
import MediaQuery from 'react-responsive';
import { Facets } from '../../reactComponents/filters';
import { DefineAdSlot, InitializeGPT } from '../../reactComponents/gptAds';
export interface DirectorySidebarProps {
    updateHiddenState: (value: boolean) => void;
    removeAdSlot?: (slotId: string) => void;
    addAdSlot?: (slotId: string, slot: any) => void;
    adSlots?: any;
    adSlotCount?: number;
    setAdSlotCount?: (number, any) => void;
}
export const DirectorySidebar = ({ updateHiddenState, removeAdSlot = null, addAdSlot = null, adSlots = null, adSlotCount = null , setAdSlotCount = null}: DirectorySidebarProps) => {
    const { filtersLabelText, resetFiltersLabelText, sidebarAds, noFilterText, showFilterCounts } = useAppSelector(state => state.settings);
    const { apiParams, currentAppliedFilters } = useAppSelector(state => state.directory);
    const dispatch = useAppDispatch();
    const handleFilterUpdate = (facetName, filterName, facetType) => {
        dispatch(updateFilter({
            facetName: facetName,
            facetValue: filterName,
            facetType: facetType,
        }))
        updateHiddenState(false);
    }

    const handleResetFilters = () => {
        dispatch(resetFilters());
    }
    const [mobileFilterOpen, setMobileFilterOpen] = useState(false);
    const { data, isSuccess, isLoading } = useGetDirectoryQuery(apiParams);
    return (
        <div className="directory-listing-sidebar" >
            <MediaQuery maxWidth={1024}>
                {data && data.facets && data.facets.length > 0 &&
                    <div className="mobile-filter-accordion">
                        <button className="accordion-trigger" type="button" id="Accordion_trigger_directory_mobile" aria-controls="Accordion_content_directory-mobile" aria-expanded={mobileFilterOpen} onClick={() => setMobileFilterOpen(!mobileFilterOpen)}>
                            <span className="h5-style">Filter By:</span>
                            <i className="accordion-icon-directory ph ph-sliders"></i>
                        </button>
                        <div className="accordion-content accordion-panel" role="region" id="Accordion_content_directory_mobile" aria-hidden={!mobileFilterOpen} aria-labelledby="Accordion_trigger_directory_mobile">
                            <Facets
                                facets={data.facets}
                                filterUpdate={handleFilterUpdate}
                                appliedFilters={currentAppliedFilters}
                                resetFilters={handleResetFilters}
                                filtersLabelText={filtersLabelText}
                                resetFiltersLabelText={resetFiltersLabelText}
                                noFilterText={noFilterText}
                                showFilterCounts={showFilterCounts}>
                                
                            </Facets>
                        </div>
                    </div>}
            </MediaQuery>

            <MediaQuery minWidth={1025}>
                {
                    data && data.facets && data.facets.length > 0 &&
                    <Facets
                        facets={data.facets}
                        filterUpdate={handleFilterUpdate}
                        appliedFilters={currentAppliedFilters}
                        resetFilters={handleResetFilters}
                        filtersLabelText={filtersLabelText}
                        resetFiltersLabelText={resetFiltersLabelText}
                        noFilterText={noFilterText}
                        showFilterCounts={showFilterCounts}>
                        
                    </Facets>
                }
                {sidebarAds && sidebarAds.length > 0 && sidebarAds.map((slot, i) => (
                    <DefineAdSlot setAdSlotCount={setAdSlotCount} adSlots={adSlots} adSlotCount={adSlotCount} updateAdSlots={addAdSlot} removeSlotId={removeAdSlot} adUnit={slot.id} size={JSON.parse("[" + slot.size + "]")} key={i} />
                ))}
            </MediaQuery>
        </div>
    )
}