import React, { useState, useRef, useEffect } from 'react';
import SearchInput from '../../searchResults/components/search-input';
import { useAppDispatch } from '../app/store';
import { updateSearchTerm } from '../features/directory/directory-slice';
import { useAppSelector } from '../hooks/hooks';

export interface DirectorySearchProps {
    updateHiddenState: (value: boolean) => void;
}

export const DirectorySearch = ({updateHiddenState }: DirectorySearchProps) => {
    const { apiParams } = useAppSelector(state => state.directory);
    const [searchTerm, setSearchTerm] = useState<string>(apiParams.searchTerm);
    const dispatch = useAppDispatch();
    const onSearch = (value: string) => {
        dispatch(updateSearchTerm({ searchTerm: value }))
        updateHiddenState(false);
    }

    useEffect(() => {
        setSearchTerm(apiParams.searchTerm);
    }, [apiParams.searchTerm])

    return (
        <div className="directory-listing-search">
            <SearchInput
                placeholder="Search"
                value={searchTerm}
                onChange={(value) => setSearchTerm(value)}
                onEnter={(value) => {
                    onSearch(value);
                }}
                ref={useRef(null)}
            />
            <div className="search-button">
                <button className="btn-primary" onClick={() => onSearch(searchTerm)}>Search</button>
            </div>
        </div>
    );
}